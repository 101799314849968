<template>
  <div>
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>Placements</h5>
      </div>
    </div>
    <!--Banner Wrap End-->

    <div class="iq_content_wrap">
      <!--About Us START-->
      <section class="about-us-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="text">
                <!--Heading Wrap Start-->
                <div class="iq_heading_1 text-left">
                  <h4>TRAINING & <span>PLACEMENTS</span></h4>
                </div>
                <!--Heading Wrap End-->
                <p>
                  College has training, placement and career guidance cell which organizes seminars,
                  training programs and implant training. Also it organizes visits to leading industries
                  and research organizations and guidance for competitive exams like TOFEL,G-PAT, GRE etc.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="text-center">
                <img height="300px" src="images/logo.png" alt="about_us">
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container companies-list">
        <div class="row">
          <div class="col-xs-6 col-sm-4 col-md-3" v-for="(company, index) in companies" :key="index">
            <div class="company-image mb-3 shadow">
              <img class="image" :src="company.image" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['companies'])
  }
}
</script>

<style lang="scss">
.companies-list {
  margin-bottom: 30px;
  .company-image {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    border: 1px solid rgba(0,0,0,.075);
    border-radius: 10px;
    margin-bottom: 15px;
    .image {
      width: 100%;
      height: 180px;
      object-fit: contain;
    }
  }
}
</style>
